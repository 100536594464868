import { Stack } from '@mui/material';
import { ImportSSHKey, useImportSSHKey } from 'api/sshKeys';
import { FormDialog } from 'component/new_design/base/dialogs/FormDialog';
import { TextField } from 'component/new_design/base/forms/TextField';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ImportSSHKeyDialogProps {
  readonly siteId: string;
  readonly onSave: () => void;
  readonly onClose: () => void;
}

export const ImportSSHKeyDialog = ({ siteId, onSave, onClose }: ImportSSHKeyDialogProps) => {
  const { t } = useTranslation();

  const importKey = useImportSSHKey(siteId);

  const methods = useForm<ImportSSHKey>({ defaultValues: { name: '', passphrase: '', key: '' } });

  const handleSubmit = async (data: ImportSSHKey) => {
    await importKey.mutateAsync(data);
    onSave();
    onClose();
  };

  return (
    <FormDialog
      title={t('import_key')}
      methods={methods}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={
        <Stack gap={4}>
          <TextField name="name" fullWidth label={t('key_name')} rules={{ required: true }} />

          <TextField
            name="passphrase"
            fullWidth
            label={t('key_passphrase')}
            rules={{ required: true }}
          />

          <TextField name="key" fullWidth label={t('key')} rules={{ required: true }} />
        </Stack>
      }
      submitText={t('import')}
    />
  );
};
